<template>
  <div class="enterprise-item">
    <div class="body">
        <div
          class="column products"
          v-for="item in productadvList"
          :key="item.id"
        >
          <ul>
            <li>
              <a class="image" href="javascript:;">
                <img :src="item.image" alt="" class="item-img"/>
              </a>
              <div class="info">
                <p class="name ellipsis-2">
                  {{ item.name }}
                </p>
                <div class="agree">
                  {{ item.summary }}
                </div>
              </div>
            </li>
          </ul>
        </div>
    </div> 
    </div>
</template>

<script>
import { ref } from 'vue'
import { findBrand } from '@/api/home'
import { useLazyData } from '@/hooks'
export default {
  name: 'HomeBrand',
  components: {  },
  setup () {
     const { target, result } = {
    "target": "操作成功",
    "result":  [
            {
                "id": "1586920235652665346",
                "name": "软件研发",
                "summary": "我们的科技公司专注于软件研发，拥有一支技术精湛、经验丰富的研发团队。我们利用最新的技术和方法，为客户提供高效、稳定、安全的软件解决方案。我们的产品涵盖了企业级应用、移动应用、Web应用等领域，旨在帮助客户提高效率、降低成本、增加收益。",
                "createTime": "2022-10-31 11:17:22",
                "image":require('@/assets/images/tech/软件.png'),
                // "image": "http://demo.hyxdsoft.com/img/web10001/al1.jpg"
            },
            {
                "id": "1586920235652665346",
                "name": "硬件研发",
                "summary": "我们的科技公司在硬件研发方面具有实力，我们能够根据客户需求，设计、研发、生产出性能优越、稳定可靠的硬件产品。我们的硬件研发团队由一批经验丰富、技术精湛的工程师组成，他们致力于为客户提供高质量、高效率的硬件解决方案。",
                "createTime": "2022-10-31 11:17:22",
                "image":require('@/assets/images/tech/硬件.png'),
                // "image": "http://demo.hyxdsoft.com/img/web10001/al2.jpg"
            },
            {
                "id": "1586920235652665346",
                "name": "产品定制",
                "summary": "我们的科技公司提供产品定制服务，我们能够根据客户的需求和要求，为客户定制出符合其个性化需求的产品。我们的定制服务涵盖了产品设计、研发、生产、销售等全流程，旨在帮助客户实现其独特的商业目标",
                "createTime": "2022-10-31 11:17:22",
                "image":require('@/assets/images/tech/产品定制.png'),
                // "image": "http://demo.hyxdsoft.com/img/web10001/al3.jpg"
            },
            // {
            //     "id": "1586920235652665346",
            //     "name": "不可替代",
            //     "summary": "把产品的优势毫无保留地展示给客户",
            //     "createTime": "2022-10-31 11:17:22",
            //     "image": "http://demo.hyxdsoft.com/img/web10001/al4.jpg"
            // },
            // {
            //     "id": "1586920235652665346",
            //     "name": "价格优势",
            //     "summary": "客户越来越重视产品的品牌知名度",
            //     "createTime": "2022-10-31 11:17:22",
            //     "image": "http://demo.hyxdsoft.com/img/web10001/al5.jpg"
            // },
            // {
            //     "id": "1586920235652665346",
            //     "name": "特殊优势",
            //     "summary": "将其作为重要的卖点介绍给客户",
            //     "createTime": "2022-10-31 11:17:22",
            //     "image": "http://demo.hyxdsoft.com/img/web10001/al6.jpg"
            // }
        ]
}
    return { productadvList: result, target }
  }
}
</script>


<style scoped lang="less">
.enterprise-list {
  background-color: #fff;
  padding: 20px;
}

// 主体样式
.login-section {
  height: 688px;
  position: relative;
  .wrapper {
    width: 680px;
    position: absolute;
    left: 50%;
    margin-left: -28%;
    top: 54px;
    nav {
      font-size: 24px;
      height: 55px;
      padding: 20px 40px;
      text-align: center;
      align-items: center;
      color: #fff;
    }
  }
}

// 账号容器
.account-box {
  .toggle {
    padding: 10px 40px;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }
  .form {
    padding: 20px 10px;
    &-item {
      margin-bottom: 28px;
    }
    .video-play {
      border: 10px solid #e4e4e4;
    }
    .agree {
      font-size: 16px;
      padding: 10px 20px;
      align-items: center;
      color: #fff;
    }
  }
}

.enterprise-item {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;
  .head {
    height: 50px;
    line-height: 50px;
    background: #f5f5f5;
    padding: 0 20px;
    overflow: hidden;
    span {
      margin-right: 20px;
      &.down-time {
        margin-right: 0;
        float: right;
        i {
          vertical-align: middle;
          margin-right: 3px;
        }
        b {
          vertical-align: middle;
          font-weight: normal;
        }
      }
    }
    .del {
      margin-right: 0;
      float: right;
      color: #999;
    }
  }
  .body {
    display: flex;
    background: #fff;
    flex-wrap: wrap;
    align-items: stretch;
    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;
      > p {
        padding-top: 10px;
      }
      &:first-child {
        border-left: none;
      }
      &.products {
        flex: 1;
        padding: 0;
        align-self: center;
        ul {
          li {
            border-bottom: 1px solid #f5f5f5;
            padding: 10px;
            width: 1200px;
            display: flex;
            &:last-child {
              border-bottom: none;
            }
            .image {
              width: 340px;
              height: 200px;
              border: 1px solid #f5f5f5;
              .item-img{
              width: 320px;
              height: 180px;
              }
            }
            .info {
              width: 900px;
              text-align: left;
              padding: 0 10px;
              padding-left:45px;
              p {
                margin-bottom: 5px;
                &.name {
                  height: 38px;
                  font-weight: bold;
                  font-size: 30px;
                  text-align: center;
                }
                // &.attr {
                //   color: #999;
                //   font-size: 14px;
                //   // overflow: visible;
                //   width: 250px;
                //   span {
                //     margin-right: 5px;
                //   }
                // }
              }
              div{
                margin-bottom: 5px;
                &.agree{
                  color: #999;
                  font-size: 16px;
                }
                // &.attr {
                //   color: #999;
                //   font-size: 14px;
                //   // overflow: visible;
                //   width: 550px;
                //   span {
                //     margin-right: 5px;
                //   }
                // }
              }
            }
            .enterprise {
              width: 100px;
            }
            .count {
              width: 80px;
            }
          }
        }
      }
      &.state {
        width: 120px;
        .green {
          color: #999;
        }
      }
      &.amount {
        width: 200px;
        .red {
          color: #999;
        }
      }
      &.action {
        width: 140px;
        a {
          display: block;
          &:hover {
            color: #999;
          }
        }
      }
    }
  }
}
</style>